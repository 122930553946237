import type { ReactNode } from 'react';
import cx from 'classnames';
import { HalfAndHalfContent } from './half-and-half-content';
import type { Link } from '@dx-ui/osc-link';
import type { Media } from '../common.types';
import type { TResponsiveImage } from '@dx-ui/osc-responsive-image';
import type { CaptionProps } from '@dx-ui/osc-caption';

export type MarkdownLink = {
  adaDescription?: string;
  isNewWindow?: boolean;
  markdown: string;
  url: string;
};

export type HalfAndHalfProps = {
  /**
   * sets position of image left or right
   */
  index: number;
  /**
   * should the half and half be flipped?
   */
  isReverse?: boolean;
  /**
   * the url to the image.
   */
  imageUrl?: string;
  captionData?: TResponsiveImage['captionData'];
  /**
   * whether the background color should be altered and if they should be on `even` or `odd` components.
   */
  showBackgroundOn?: 'even' | 'odd';
  /**
   * renders the headline as an heading.
   */
  headline?: string;
  /**
   * renders the description as a paragraph.
   */
  description?: string;
  /**
   * Long description as a paragraph.
   */
  copy?: string;
  /**
   * the description of the image being rendered.
   */
  imageAltText?: string;
  link?: Link & { experimentationConfiguration?: CmsExperimentationConfiguration };
  id?: string;
  brandComponentTheme?: CmsBrandComponentTheme;
  /**
   * Adds an image carousel to a single Half and Half content block
   */
  hasImageCarousel?: boolean;
  /**
   * Loads the carousel images
   */
  carouselImages?: { alt: string; url: string; captionData?: CaptionProps }[];
  /**
   * renders the component with a parallax animation.
   */
  hasParallax?: boolean;
  /**
   * the speed of Parallax
   */
  speed?: number;
  /**
   * Props for custom video player or YouTube player.
   */
  media?: Media;
  /**
   * CMS document editor button
   */
  cmsDocumentControl?: ReactNode;
  /**
   * Translation CSS classes to add to each item
   */
  cmsTranslationClasses?: string;
  /**
   * Brand code - remove in NHCBP-5753
   */
  brandCode?: string;
};

export const getBackgroundClassName = (
  backgroundFilter: string,
  isElemOdd: boolean,
  compThemeName?: CmsBrandComponentTheme
) => {
  /* In the case of the dark theme, all the half & half UI items will procure the theme background(bg) color, there will be no alternating colour.
  The light theme will have an alternating(bg-alt) & its theme(bg) colour. Default would remain the same for half&half as earlier.
  */

  if (compThemeName && compThemeName.includes('dark')) {
    return 'bg-bg brand-wa:bg-transparent brand-ou:bg-secondary';
  }
  if (backgroundFilter === 'odd' && isElemOdd) {
    return 'bg-bg-alt brand-ey:bg-bg-light brand-gu:bg-quarternary brand-hi-refresh:bg-bg-light brand-nd:bg-bg-light brand-ou:bg-bg-light';
  }
  if (backgroundFilter === 'even' && !isElemOdd) {
    return 'bg-bg-alt brand-ey:bg-bg-light brand-gu:bg-quarternary brand-hi-refresh:bg-bg-light brand-nd:bg-bg-light brand-ou:bg-bg-light';
  }
  return 'bg-bg brand-wa:bg-transparent brand-ou:bg-secondary';
};

/**
 * HalfAndHalf renders a component with an image on one half and attached content of headline, text and a CTA on the other half.
 *
 * The `index` prop is used by HalfAndHalf components to alternate their layout so their images appear on alternating sides of the page, this can be done on single or multiple components.
 */
export const HalfAndHalf = ({
  index,
  isReverse = false,
  showBackgroundOn = 'even',
  brandComponentTheme,
  hasParallax = false,
  id,
  cmsTranslationClasses,
  media,
  hasImageCarousel,
  carouselImages,
  brandCode,
  ...rest
}: HalfAndHalfProps) => {
  const currElemIndxType = !Number.isNaN(index) && (index % 2 === 0 ? 'even' : 'odd');
  const isPositionOdd = isReverse ? currElemIndxType === 'even' : currElemIndxType === 'odd';
  // Hampton theme overrides - remove in NHCBP-5753
  const isHamptonBrand = brandCode === 'HP';
  const containerBgClass = !isHamptonBrand
    ? getBackgroundClassName(showBackgroundOn, isPositionOdd, brandComponentTheme)
    : cx({
        '!text-text-inverse': brandComponentTheme === 'dark',
      });

  return (
    <div
      id={id}
      className={cx(
        'w-full',
        isPositionOdd ? `half-and-half-odd-${index}` : `half-and-half-even-${index}`,
        containerBgClass,
        cmsTranslationClasses
      )}
      data-testid="halfnhalf-sections-wrapper"
    >
      <HalfAndHalfContent
        brandCode={isHamptonBrand ? brandCode : undefined}
        brandComponentTheme={isHamptonBrand ? brandComponentTheme : undefined}
        index={index}
        isPositionOdd={isPositionOdd}
        hasParallax={hasParallax}
        media={media}
        hasImageCarousel={hasImageCarousel}
        carouselImages={carouselImages}
        {...rest}
      />
    </div>
  );
};
